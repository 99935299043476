import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Plausible from "plausible-tracker";
import HomePage from "../pages/HomePage";
import PeoplePage from "../pages/PeoplePage";
import WhatWeDoPage from "../pages/WhatWeDoPage";
import ResearchThemesPage from "../pages/ResearchThemesPage";
import ResourcesPage from "../pages/ResourcesPage";
import NewsEventsPage from "../pages/NewsEventsPage";
import VacanciesPage from "../pages/VacanciesPage";
import PhdPage from "../pages/PhdPage";
import ContactPage from "../pages/ContactPage";
import PublicationsPage from "../pages/PublicationsPage";
import BlogPostPage from "../pages/BlogPostPage";
import GroupPage from "../pages/GroupPage";
import PersonPage from "../pages/PersonPage";
import PageNotFound from "../pages/PageNotFound";
import PhdProjectPage from "../pages/PhdProjectPage";
import FacilitiesPage from "../pages/FacilitiesPage";
import FacilityPage from "../pages/FacilityPage";
import { FacilitiesContext } from "../contexts";

const App = () => {

  useEffect(() => {
    if (!window.location.hostname.includes("localhost")) {
      const { enableAutoPageviews } = Plausible({domain: "sidb.org.uk"});
      enableAutoPageviews();
    }
  }, [])

  const [facilities, setFacilities] = useState(null);

  return (
    <FacilitiesContext.Provider value={[facilities, setFacilities]}>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={ <HomePage />} />
          <Route path="/people/:type" element={ <PeoplePage />} />
          <Route path="/what-we-do/" element={ <WhatWeDoPage />} />
          {/* <Route path="/research-themes/" element={ <ResearchThemesPage />} /> */}
          <Route path="/resources/" element={ <ResourcesPage />} />
          <Route path="/facilities/" element={ <FacilitiesPage />} />
          <Route path="/facilities/:slug" element={ <FacilityPage />} />
          <Route path="/publications/" element={ <PublicationsPage />} />
          <Route path="/news-events/" element={ <NewsEventsPage />} />
          <Route path="/news-events/:slug" element={ <BlogPostPage />} />
          <Route path="/vacancies/" element={ <VacanciesPage />} />
          <Route path="/phds/" element={ <PhdPage />} />
          <Route path="/phds/:slug" element={ <PhdProjectPage />} />
          <Route path="/contact/" element={ <ContactPage />} />
          <Route path="/:slug" element={ <GroupPage />} />
          <Route path="/:slug/:slug" element={ <PersonPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </FacilitiesContext.Provider>
  );
};

App.propTypes = {
  
};

export default App;